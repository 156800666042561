/* color */
.header {
  background-image: linear-gradient(to right, #fff 30%, #1E487C);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .header:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100px;
    left: 50%;
    top: 0;
    background-image: url("/themes/default/assets/static/images/default/bg-header.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat; }
    @media (max-width: 767.98px) {
      .header:before {
        height: 86px; } }
  .header .navbar {
    min-height: 86px; }
    .header .navbar-brand--logo {
      height: 74px; }
      @media (max-width: 767.98px) {
        .header .navbar-brand--logo {
          height: 60px; } }
    @media (min-width: 768px) {
      .header .navbar {
        min-height: 100px; } }
    @media (min-width: 1200px) {
      .header .navbar {
        padding-left: 3rem;
        padding-right: 3rem; } }
  .header .nav-item .nav-link {
    color: #fff;
    transition: all .3s ease; }
    .header .nav-item .nav-link:focus {
      color: #fff; }
    .header .nav-item .nav-link:hover {
      color: #DC5830;
      outline: none; }
  .header .nav-item.active .nav-link, .header .nav-item.show .nav-link {
    color: #DC5830; }
  .header .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 1.125rem;
    font-weight: 400; }
  .header .dropdown.show {
    position: static; }
  .header .dropdown-menu {
    border: none;
    border-radius: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), inset 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
    width: 100%;
    margin-top: 0;
    padding: 1.5rem 0; }
  .header .dropdown-toggle:after {
    content: none; }
  .header .dropdown-item.active {
    background-color: transparent;
    color: #DC5830; }
  @media (min-width: 992px) {
    .header .sub-menu {
      column-count: 5;
      padding: 0 2.5rem; }
      .header .sub-menu .navbar-nav {
        display: block; }
      .header .sub-menu .nav-item {
        page-break-inside: avoid;
        /* Fix bug::list style on Edge */
        list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=); } }
  @media (min-width: 1325px) and (max-width: 1357px) {
    .header .sub-menu {
      padding: 0 1.4rem; } }
  .header .sub-menu .nav-item .nav-link {
    font-weight: 500;
    color: #000;
    cursor: pointer; }
    .header .sub-menu .nav-item .nav-link:hover {
      color: #DC5830; }
  .header .sub-menu .nav-item.active .nav-link {
    color: #DC5830; }
  .header .sub-menu .dropdown-menu {
    position: static;
    display: block;
    box-shadow: none;
    padding: 0; }
  .header .sub-menu .dropdown-item {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    white-space: normal; }
    .header .sub-menu .dropdown-item:before {
      content: '';
      width: .5rem;
      height: .5rem;
      background-color: #DC5830;
      transform: rotate(45deg);
      margin-right: 1rem;
      position: absolute;
      left: 0.3rem;
      top: 0.9rem; }
    .header .sub-menu .dropdown-item:hover {
      background-color: transparent;
      color: #DC5830; }
  @media (max-width: 991.98px) {
    .header .navbar-toggler {
      border-radius: 0;
      border: 1px solid #fff; }
      .header .navbar-toggler-icon {
        color: #fff;
        background-image: none; }
        .header .navbar-toggler-icon:after {
          content: '\F0C9';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          line-height: 1.5; }
      .header .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
        content: '\F00D'; }
    .header .navbar-brand {
      margin-right: 0; }
    .header .navbar-collapse {
      background-color: white;
      margin: 0 -1rem -1rem;
      padding: 0 1rem 1rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), inset 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
      max-height: 75vh;
      overflow: auto; }
    .header .navbar-nav > .nav-item {
      padding-top: .7rem;
      padding-bottom: .7rem; }
      .header .navbar-nav > .nav-item:not(.active) .nav-link {
        color: #000; }
      .header .navbar-nav > .nav-item:not(:first-child) {
        border-top: 1px solid #EEEEEE; }
    .header > .navbar-nav > .nav-item:last-child {
      display: none; }
    .header .dropdown-menu {
      padding: 0;
      box-shadow: none; } }

main {
  margin-top: 100px; }
  @media (max-width: 767.98px) {
    main {
      margin-top: 86px; } }

.footer {
  font-size: 1rem;
  font-weight: 400;
  background-color: #1E487C;
  color: #fff;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background-image: url("/themes/default/assets/static/images/default/bg-footer.png");
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat; }
  .footer .nav .nav {
    padding-left: 1rem; }
  .footer .nav-link {
    padding: 0.3rem 0;
    color: #fff; }
  .footer address {
    font-weight: 300; }
  .footer .media {
    margin-bottom: .5rem; }

.product {
  padding: 5rem 0;
  position: relative; }
  .product:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/home/bg-product.jpg");
    z-index: -2; }
  .product:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to left, rgba(181, 214, 255, 0.86), rgba(30, 72, 124, 0.86));
    z-index: -1; }
  .product:hover {
    text-decoration: none; }
  .product .product__image {
    min-height: 200px;
    text-align: center;
    margin-bottom: 1.5rem;
    transform: scale3d(0.9, 0.9, 0.9);
    transition: all .4s ease; }
  .product .product__title {
    font-size: 1.5rem;
    text-align: center;
    color: #000; }
  .product .product__body {
    display: block;
    position: relative; }
    .product .product__body .btn {
      position: absolute;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%);
      opacity: 0; }
      .product .product__body .btn:hover {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff; }
    .product .product__body:hover {
      text-decoration: none; }
      .product .product__body:hover .btn {
        opacity: 1; }
      .product .product__body:hover .product__image {
        transform: scale3d(1, 1, 1);
        opacity: .7; }

.portfolio .bg-half-screen {
  width: 49.3vw;
  position: absolute;
  height: 100%; }
  @media (max-width: 991.98px) {
    .portfolio .bg-half-screen {
      position: relative;
      min-height: 300px;
      width: 100%; } }

.article {
  position: relative;
  background-image: linear-gradient(to bottom left, transparent 60%, #FD9D46); }
  .article:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border-radius: 0 0 0 500px;
    background-image: linear-gradient(to bottom left, #fff, #fff); }
  .article:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border-radius: 0 300px 0 0;
    background-image: linear-gradient(to top, #FD9D46, transparent 70%); }

.video-home {
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }
  @media (max-width: 1199.98px) {
    .video-home {
      width: 560px;
      height: 315px; } }
  @media (max-width: 991.98px) {
    .video-home {
      width: 100%;
      height: 315px; } }

.contact {
  position: relative; }
  .contact:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(253, 198, 127, 0.55), rgba(253, 157, 70, 0.55)); }
  .contact .card {
    border: none; }
  .contact address, .contact .media-body {
    font-weight: 400; }
  .contact .media-body {
    font-size: 0.875rem; }

.banner {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .banner .slide-banner__container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 560px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative; }
    .banner .slide-banner__container:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.36); }
  .banner .slide-banner__text {
    color: #fff;
    text-align: center;
    margin-bottom: 5rem;
    z-index: 0; }
    .banner .slide-banner__text .h2 {
      position: relative;
      margin-bottom: 1.5rem; }
      .banner .slide-banner__text .h2:after {
        content: '';
        width: 90%;
        height: 2px;
        position: absolute;
        bottom: -0.4rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff; }
      @media (max-width: 767.98px) {
        .banner .slide-banner__text .h2 {
          font-size: 1.75rem; } }
  .banner .slide-banner .owl-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0.7rem; }
  .banner .card {
    padding: 2rem; }
    @media (min-width: 992px) {
      .banner .card {
        min-height: 520px; } }
    .banner .card .blockquote {
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 1rem; }
      .banner .card .blockquote .h4 {
        font-weight: 500; }
      .banner .card .blockquote:before, .banner .card .blockquote:after {
        font-family: 'icomoon';
        font-size: 2rem; }
      .banner .card .blockquote:before {
        content: '\E977';
        position: absolute;
        top: -2.5rem;
        left: 0; }
      .banner .card .blockquote:after {
        content: '\E978';
        position: absolute;
        right: 0;
        bottom: -2.5rem; }

.btn-outline-primary {
  color: #000;
  border-color: #DC5830;
  border-radius: 0;
  padding: 0.5rem 1.5rem; }
  .btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #DC5830;
    color: #fff;
    border-color: #DC5830; }
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:focus {
    box-shadow: none; }

.btn-outline-secondary {
  color: #fff;
  border-color: #fff;
  border-radius: 0;
  padding: 0.5rem 1.5rem; }
  .btn-outline-secondary:hover, .btn-outline-secondary:not(:disabled):not(.disabled):active {
    background-color: #fff;
    color: #1E487C; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:focus {
    box-shadow: none; }

.btn-link {
  padding: 0;
  font-size: inherit; }
  .btn-link:hover {
    text-decoration: none; }

.card {
  border-radius: 0; }

.card.card--shadow {
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.28);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.card.card--article {
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  transition: all .4s ease; }
  .card.card--article .card-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 230px; }
  .card.card--article .card-body {
    color: #000; }
    .card.card--article .card-body .ellipsis {
      overflow: hidden;
      height: 72px; }
  .card.card--article .card-date {
    color: #6E6E6E;
    font-weight: 400;
    margin-bottom: 0.625rem; }
  .card.card--article .card-title {
    font-weight: 400;
    margin-bottom: 0.625rem; }
  .card.card--article:hover {
    text-decoration: none;
    background-color: #EEEEEE; }
    .card.card--article:hover .card-body, .card.card--article:hover .card-date {
      color: #1E487C; }

.card.card--product {
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  transition: all .4s ease;
  height: 100%; }
  .card.card--product .card-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 230px; }
  .card.card--product .card-title {
    font-weight: 500;
    margin-bottom: 0.625rem; }
  .card.card--product .card-body {
    color: #000; }
    .card.card--product .card-body table {
      width: 100%; }
      .card.card--product .card-body table th {
        text-align: center;
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: 400; }
      .card.card--product .card-body table td {
        color: #DC5830;
        font-size: 1.125rem;
        text-align: center;
        margin-bottom: 0; }
  .card.card--product:hover {
    text-decoration: none;
    background-color: #EEEEEE; }
    .card.card--product:hover .card-body {
      color: #1E487C; }

.card.card--album {
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  transition: all .4s ease;
  position: relative; }
  .card.card--album .card-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 230px;
    position: relative; }
    .card.card--album .card-image:before {
      content: '';
      transition: all .4s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0; }
  .card.card--album .card-body {
    color: #000; }
  .card.card--album .card-title {
    text-align: center;
    font-weight: 400;
    margin-bottom: 0; }
  .card.card--album .btn {
    opacity: 0;
    position: absolute;
    top: 115px;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .4s ease; }
    .card.card--album .btn:hover {
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff; }
  .card.card--album:hover {
    text-decoration: none;
    background-color: #EEEEEE; }
    .card.card--album:hover .card-body {
      color: #1E487C; }
    .card.card--album:hover .card-image:before {
      opacity: 1; }
    .card.card--album:hover .btn {
      opacity: 1; }

.owl-theme .owl-dots .owl-dot > span {
  border-radius: 0 !important;
  transform: rotate(45deg);
  background-color: transparent !important;
  border: 1px solid #fff; }

.owl-theme .owl-dots .owl-dot.active > span {
  background-color: #fff !important;
  border: 1px solid #fff; }

.owl-theme .owl-dots .owl-dot:not(.active):hover > span {
  background: #DC5830; }

.owl-theme.owl-dot-orange .owl-dot > span {
  border-radius: 0 !important;
  transform: rotate(45deg);
  background-color: transparent !important;
  border: 1px solid #DC5830; }

.owl-theme.owl-dot-orange .owl-dot.active > span {
  background-color: #DC5830 !important;
  border: 1px solid #DC5830; }

.owl-theme.owl-dot-orange .owl-dot:not(.active):hover > span {
  background: #DC5830; }

.owl-inline-slide .item {
  padding: 1rem 0 !important; }

.owl-portfolio {
  width: 49.3vw;
  height: 100%; }
  @media (max-width: 991.98px) {
    .owl-portfolio {
      min-height: 300px;
      width: 100%; } }
  .owl-portfolio .owl-stage-outer, .owl-portfolio .owl-stage, .owl-portfolio .owl-item {
    height: 100%; }

.owl-product .owl-item {
  margin-bottom: 13px; }

.breadcrumb {
  background-color: transparent;
  border-bottom: 1px solid #EEEEEE;
  padding-left: 0;
  padding-right: 0; }
  .breadcrumb-item {
    font-weight: 400;
    color: #6E6E6E; }
    .breadcrumb-item a {
      font-weight: 400;
      color: #6E6E6E; }
    .breadcrumb-item.active {
      color: #000; }
    .breadcrumb-item:not(:first-child):before {
      content: '>'; }

.pagination .page-item {
  padding-left: .5rem;
  padding-right: .5rem; }
  .pagination .page-item.disabled .page-link {
    color: #BEBEBE; }
  .pagination .page-item:not(.disabled) .page-link {
    color: #000; }
  .pagination .page-item.active .page-link {
    background-color: transparent;
    color: #000;
    border: 1px solid #DC5830; }

.pagination .page-link {
  border-radius: 0 !important; }
  .pagination .page-link:focus {
    box-shadow: none; }

html[lang='en'] {
  /* define font tags and class */
  /* define font class only */ }
  html[lang='en'] .h1, html[lang='en'] h1 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 3rem; }
  html[lang='en'] .h2, html[lang='en'] h2 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 2.25rem; }
  html[lang='en'] .h3, html[lang='en'] h3 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1.75rem; }
  html[lang='en'] .h4, html[lang='en'] h4 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1.25rem; }
  html[lang='en'] .h5, html[lang='en'] h5 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1.125rem; }
  html[lang='en'] .h6, html[lang='en'] h6 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1rem; }
  html[lang='en'] .lead {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-size: 1.25rem; }
  html[lang='en'] .sub-title {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-size: 0.875rem; }
  html[lang='en'] .display-1 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 3.5rem;
    line-height: 1; }

html[lang='th'] {
  /* define font tags and class */
  /* define font class only */ }
  html[lang='th'] .h1, html[lang='th'] h1 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 3rem; }
  html[lang='th'] .h2, html[lang='th'] h2 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 2.25rem; }
  html[lang='th'] .h3, html[lang='th'] h3 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1.75rem; }
  html[lang='th'] .h4, html[lang='th'] h4 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1.25rem; }
  html[lang='th'] .h5, html[lang='th'] h5 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1.125rem; }
  html[lang='th'] .h6, html[lang='th'] h6 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 1rem; }
  html[lang='th'] .lead {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-size: 1.25rem; }
  html[lang='th'] .sub-title {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-size: 0.875rem; }
  html[lang='th'] .display-1 {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-size: 3.5rem;
    line-height: 1; }

body {
  letter-spacing: 0.3px;
  font-weight: 300; }
  html[lang='en'] body {
    font-family: "Kanit", sans-serif; }
  html[lang='th'] body {
    font-family: "Kanit", sans-serif; }

.date {
  font-size: 0.875rem;
  font-weight: 400; }

.bg-primary {
  background-color: #fff; }

.bg-secondary {
  background-color: #1E487C; }

.bg-orange {
  background-color: #DC5830; }

.bg-lightorange {
  background-color: #FDC67F; }

.bg-grey {
  background-color: #EEEEEE; }

.text-black {
  color: #000; }

.text-white {
  color: #fff; }

.text-blue {
  color: #1E487C; }

.text-orange {
  color: #DC5830; }

.bg-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.link {
  color: inherit; }
  .link:hover {
    text-decoration: none;
    color: #DC5830; }

.border-heading {
  display: inline-block;
  position: relative; }
  .border-heading:after {
    content: '';
    width: 90%;
    height: 2px;
    position: absolute;
    bottom: -0.4rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #DC5830; }

.max-height-md {
  max-height: 330px; }
